import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import LoginForm from './components/login-form';
import { Context } from './index';
import { User } from './models/user';
import { UserService } from './services/user-service';

function App() {
    const { store } = useContext(Context);
    const [users, setUser] = useState<User[]>([]);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            store.checkAuth();
        }
    }, []);

    async function getUsers() {
        try {
            const response = await UserService.fetchUsers();
            setUser(response.data);
        } catch (e) {
            console.log(e);
        }
    }

    if (store.isLoading) {
        return <div>Loading...</div>;
    }

    if (!store.isAuth) {
        return <LoginForm />;
    }

    return (
        <div>
            <h1>{store.isAuth ? `logged in ${store.user.email}` : 'you need to log in'}</h1>
            <h2>{store.user.isActivated ? 'account is activated' : 'account is not activated'}</h2>
            <button onClick={() => store.logout()}>Log out</button>
            <div>
                <button onClick={getUsers}>Get user list</button>
            </div>
            {users.map((user) => (
                <div key={user.email}>{user.email}</div>
            ))}
        </div>
    );
}

export default observer(App);
